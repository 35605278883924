/**
 * Created by katarinababic on 27.6.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../../../Store/useRootStore';
import { WebinarEntry } from '../../../Model/Explore/WebinarEntry';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import ReactPlayer from 'react-player';
import { VideoLanguageSelector } from '../VideoLanguageSelector';
import { useState } from 'react';
import { KinasticVideoPlayer } from '../KinasticVideoPlayer';
import { EMPTY_ARRAY } from '../../../Utils/Constants';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 16px;
  background-size: cover !important;
  background-position: center 30% !important;
  background-repeat: no-repeat !important;
  height: 300px;
  border-radius: 16px 16px 0px 0px;

  @media (min-width: 600px) {
    height: 360px;
  }

  @media (min-width: 992px) {
    height: 480px;
  }

  @media (min-width: 1280px) {
    height: 560px;
  }
`;

const OverlayContainer = styled.div`
  position: absolute;
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #edeef0;
  border-radius: 8px;
  padding: 16px;
  font-weight: 700;
  text-align: center;
  min-width: 120px;
`;

const Month = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

const Day = styled.div`
  font-size: 32px;
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export type ExploreContentViewWebinarVideoProps = {
  entry: WebinarEntry;
};

export const ExploreContentViewWebinarVideo: React.FC<ExploreContentViewWebinarVideoProps> = observer(({ entry }) => {
  const { language } = useRootStore();

  const nextMeetingDate = entry.nextStartDate;
  const hasStarted = !dayjs().isBefore(nextMeetingDate);
  console.log('nextMeetingDate', nextMeetingDate);
  const videoMedias =
    hasStarted && entry.videos ? entry.videos : !hasStarted && entry.previewVideos ? entry.previewVideos : EMPTY_ARRAY;
  const image = entry.getImage(language.language)?.largeOrMediumOrSmallest;

  return (
    <Container style={{ background: `url('${image}')` }}>
      <VideoContainer>
        <KinasticVideoPlayer videos={videoMedias} showLanguageSelector={hasStarted} />
      </VideoContainer>
      {nextMeetingDate && (
        <OverlayContainer>
          <Overlay>{nextMeetingDate.format('LT')}</Overlay>
          <Overlay style={{ marginTop: 16 }}>
            <Month>{nextMeetingDate.format('ddd')}</Month>
            <Day>{nextMeetingDate.format('DD')}</Day>
            <Month>{nextMeetingDate.format('MMM')}</Month>
          </Overlay>
        </OverlayContainer>
      )}
    </Container>
  );
});
