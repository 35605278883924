/**
 * Created by neo on 28.08.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import ReactPlayer from 'react-player';
import { VideoLanguageSelector } from './VideoLanguageSelector';
import { Media } from '../../Model/Media/Media';
import { notUndefined } from '../../Utils/notUndefined';
import { useRootStore } from '../../Store/useRootStore';
import { useTrans } from '../../Store/System/LocalizationStore';

const VideoPlayer = styled(ReactPlayer)``;

export type KinasticVideoPlayerProps = {
  videos: Media[];
  showLanguageSelector?: boolean;
};

export const KinasticVideoPlayer: React.FC<KinasticVideoPlayerProps> = observer(
  ({ videos, showLanguageSelector = false }) => {
    const { language } = useRootStore();
    const { t } = useTrans();

    const [selectedLanguage, setSelectedLanguage] = useState(language.language);
    const [showAIMessage, setShowAIMessage] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [countdown, setCountdown] = useState(6); // Start countdown at 6 seconds
    const [hasCountdownCompleted, setHasCountdownCompleted] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);

    const videoRef = useRef<ReactPlayer>(null);

    const videoMedia =
      videos.find((v) => v.language === selectedLanguage) ?? videos.find((v) => v.language === 'en') ?? videos[0];

    const availableLanguages = React.useMemo(
      () =>
        videos
          .flatMap((s) => s.language)
          .filter((v) => !!v)
          .filter(notUndefined),
      [videos],
    );

    const handleChangeLanguage = React.useCallback((lang: string) => {
      setCurrentTime(videoRef.current?.getCurrentTime() ?? 0);
      setSelectedLanguage(lang);
    }, []);

    const handleAIToggle = React.useCallback(() => {
      setIsPlaying((prevPaused) => !prevPaused);
      setShowAIMessage((prevShowAIMessage) => !prevShowAIMessage);
    }, []);

    useEffect(() => {
      if (countdown > 0 && !hasCountdownCompleted) {
        const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
        return () => clearTimeout(timer);
      } else {
        setIsPlaying(true); // Start video after countdown
        setHasCountdownCompleted(true); // Ensure countdown doesn't show again
      }
    }, [countdown, hasCountdownCompleted]);

    useEffect(() => {
      if (videoRef.current) {
        videoRef.current?.seekTo(currentTime, 'seconds');
      }
    }, [videoMedia, currentTime]);

    return (
      <React.Fragment>
        <VideoPlayer
          ref={videoRef}
          playsinline={true}
          url={videoMedia?.largeOrMediumOrSmallest}
          width="100%"
          height="100%"
          muted={true}
          controls={true}
          playing={isPlaying}
          loop={true}
          style={{ objectFit: 'cover' }}
        />
        {videoMedia?.aiContent && hasCountdownCompleted && (
          <div
            onClick={handleAIToggle}
            style={{
              position: 'absolute',
              top: '10px',
              left: '10px',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              padding: '5px 10px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            {t('video.aiContentToggle', { defaultValue: 'AI Translated' })}
          </div>
        )}
        {showAIMessage && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'rgba(0, 0, 0, 0.95)',
              color: 'white',
              padding: '20px',
              borderRadius: '10px',
              textAlign: 'center',
              maxWidth: '80%',
            }}
          >
            <p>
              {t('video.aiContentMessage', {
                defaultValue:
                  'This video has been AI translated. Some artifacts may appear unnatural. Please be aware that the translation may not be perfect.',
              })}
            </p>
            <button
              onClick={handleAIToggle}
              style={{
                marginTop: '10px',
                padding: '10px 20px',
                backgroundColor: 'white',
                color: 'black',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              Close
            </button>
          </div>
        )}
        {showLanguageSelector && (
          <VideoLanguageSelector
            availableLanguages={availableLanguages}
            selectedLanguage={selectedLanguage}
            onChangeLanguage={handleChangeLanguage}
          />
        )}
        {videoMedia?.aiContent && !hasCountdownCompleted && (
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 1)',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              textAlign: 'center',
              padding: '20px 60px',
            }}
          >
            <p style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>
              {t('video.aiContentTitle', {
                defaultValue:
                  'This video has been AI translated. Some artifacts may appear unnatural. Please be aware that the translation may not be perfect.',
              })}
            </p>
            <p style={{ fontSize: '18px' }}>
              {t('video.aiContentCountdown', {
                defaultValue: 'Starting in {{countdown}} seconds...',
                countdown,
              })}
            </p>
          </div>
        )}
      </React.Fragment>
    );
  },
);
